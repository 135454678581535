import React from "react"
import Homelayout from "../layouts/home"
import Seo from "../components/seo"
import Accreditations from "../components/accreditations"
import Homeaccordion from "../components/homeaccordion"
import Homeserviceslist from "../components/homeserviceslist"
import { graphql, Link } from "gatsby"
import { Col, Row, Button} from "react-bootstrap"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import styled from "styled-components"
import Clientlist from "../components/clientlist"


// styles
const Aboutleadin = styled.span`
  color: ${({ theme }) => theme.colours.common.black};
  font-size: 1em;
  text-transform: uppercase;
  padding-bottom: 15px;
  font-weight: 400;
  line-height: 1;
  font-family: ${({ theme }) => theme.font.headings};
`
const Aboutheading = styled.h2`
  color: ${({ theme }) => theme.colours.primary.main};
  font-size: 1.2em;
  text-transform: uppercase;
  padding: 10px 0;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
  font-size-adjust: 0.5;

  @media (min-width: 992px) { 
    font-size: 2rem;
  }
`

const Servicesheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
  margin: 25px 0;

  @media (min-width: 768px) { 
    margin: 100px 0 50px 0;
  }

`

const Clientheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
  margin: 25px 0;

  @media (min-width: 768px) { 
    margin: 0 0 50px 0;
  }

`

const Whyheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};
  margin: 50px 0 25px 0;

  @media (min-width: 768px) { 
  }

`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

const ServicesItem = styled.div`
  line-height: 1.2;
  margin: 10px 0 30px 0;  
  flex-wrap: wrap;
  display: flex;
`

function IndexPage ({ data }) {

  return (
    <>
    <Seo title={`${data.directus.settings.site_title} · ${data.directus.home.seo_title}`} description={data.directus.settings.site_description} author={data.directus.settings.site_author}  />
    <Homelayout logo={data.directus.settings.site_logo} heroimage={data.directus.home.hero_image} herotitle={data.directus.home.hero_title} herosubtitle={data.directus.home.hero_subtitle} footerimage={data.directus.settings.footer_image} >
        <Row className="aboutBg">
          <Col xs={12} sm={12} lg={6} className="d-md-none d-xs-block d-lg-block">
          <GatsbyImage image={data.directus.home.about_image.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.home.about_image.title} objectPosition="top top"/>
          </Col>
          <Col xs={12} md={12} lg={6}>
            <Aboutleadin className="d-none d-md-block">Who we are</Aboutleadin>
            <Aboutheading
                  dangerouslySetInnerHTML={{
                    __html: data.directus.home.about_heading,
                }} />
            <Link to="/about"><StyledButton>Our Story</StyledButton></Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Whyheading>Why Choose Us</Whyheading>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={6}>
            <Homeaccordion />
          </Col>
          <Col xs={12} sm={6} lg={6} className="d-none d-lg-block">
          <GatsbyImage image={data.directus.home.why_us_img.imageFile.childImageSharp.gatsbyImageData} alt={data.directus.home.why_us_img.title} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Servicesheading>Our Services</Servicesheading>
          </Col>
        </Row>
        <Homeserviceslist />
        <Servicesheading>Accreditations</Servicesheading>
        <Accreditations data={data.directus.accreditations} />
        <Row>
          <Col>
                <Clientheading>Our Clients</Clientheading>
          </Col>
        </Row>
        <Clientlist />
    </Homelayout>
    </>
  )
}


export default IndexPage

export const query = graphql`
query homePage {
  directus {
    accreditations {
      title
      description
      accreditation_image {
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
    settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      footer_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      contact_details
    }
    home {
      seo_title
      hero_title
      hero_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 500
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      why_us_img {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 500
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      hero_subtitle
      about_heading
      about_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [JPG])
          }
        }
      }
      accreditation
      professionalism
      projects_heading
      projects_summary
      training
      projects_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
}
`