import React from "react"
import Sitenav from "../components/sitenav"
import Sitefooter from "../components/sitefooter"
import { Container, Col, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

// styles
const Heroheading = styled.h1`
  position: absolute;
  color: ${({ theme }) => theme.colours.primary.main};
  top: 175px;
  right: 0;
  font-size: 40px;
  text-transform: uppercase;
  background: #FFF;
  padding: 15px 30px 15px 30px;


  @media (min-width: 768px) { 
    top: 175px;
    right: 15px;
    font-size: 48px;
  }
`
const Herosubheading = styled.h2`
  position: absolute; 
  color: ${({ theme }) => theme.colours.common.white};
  top: 245px;
  right: 0px;

  background: ${({ theme }) => theme.colours.primary.main};
  padding: 15px 30px 10px 25px;
  font-size: 20px;
  text-transform: uppercase;

  @media (min-width: 768px) { 
    top: 255px;
    right: 15px;
  }
`

function Homelayout ({ children, logo, heroimage, herotitle, herosubtitle, footerimage }) {

const image = getImage(heroimage.imageFile);

return (
    <>
    <Sitenav logo={logo} />
    <Container fluid className="px-0 d-none d-sm-block">
      <Row className="mx-0">
        <Col xs={12} md={12} className="heroContainer px-0">
          <GatsbyImage image={image} className="hero" alt="" objectPosition="center top" />
              <Heroheading className="d-none d-sm-block"
                dangerouslySetInnerHTML={{
                  __html: herotitle,
              }}/>
              <Herosubheading className="d-none d-sm-block"
                dangerouslySetInnerHTML={{
                  __html: herosubtitle,
              }}/>
          </Col>
        </Row>
    </Container>
    <Container>
        {children}
    </Container>
    <Sitefooter footerimage={footerimage} />
    </>
    )
}

export default Homelayout
