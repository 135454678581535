import React from "react";
import { Col, Row, Button } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

// styles
const Projecttitle = styled.h2`
  color: ${({ theme }) => theme.colours.primary.main};
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: 0 0 10px 0;
  font-size: 1.5rem;
`


const Projectdescription = styled.div`
  color: ${({ theme }) => theme.colours.common.black};
  margin-bottom: 15px;

  p {
    line-height: 1.2;
  }

  & a {
    color: ${({ theme }) => theme.colours.primary.main};
    text-decoration: none;
    transition: ${({ theme }) => theme.animations.link};
  }
  
  & a:hover {
    color: ${({ theme }) => theme.colours.primary.main};
    font-weight: bold;
  }
  
`

const Projectimg = styled(GatsbyImage)`
  margin: 0 auto;
`

const Projectrow = styled(Row)`
  margin-bottom: 50px;

  @media (min-width: 768px) { 

  & .col-md-6 {
    padding-right: 0!important;
    padding-left: 0!important;
  }
  }
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;
  text-align: center;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

const Learnlink = styled(Link)`
text-decoration: none !important;

& h2:hover {
  color: ${({ theme }) => theme.colours.common.black};
}
`
const Itemcontainer = styled.div`
  background-color: ${({ theme }) => theme.colours.common.white};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  padding: 25px;
  @media (min-width: 768px) { 
    margin-top: 25px;
  }
`

function Homeserviceslist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      services(filter: {featured: {_eq: true}, status: {_eq: "Published"}}) {
        title
        slug
        seo_description
        service_image {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 500
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
  
   
  `)

  const serviceNode = data.directus.services;
 
  return (
    
    <>
    
        {serviceNode.map((serviceItem, i) => 
        (
          <Projectrow key={i}>
{(() => {
        if (i % 2 === 0) {
          return (
          <>
            <Col xs={12} md={6}>
            <Learnlink to={`/services/${serviceItem.slug}`}>
              <Projectimg image={serviceItem.service_image.imageFile.childImageSharp.gatsbyImageData} alt={serviceItem.title} objectPosition="center center" />
            </Learnlink>
            </Col>
            <Col xs={12} md={6}>
            <Itemcontainer>
                <Learnlink to={`/services/${serviceItem.slug}`}><Projecttitle>{serviceItem.title}</Projecttitle></Learnlink>
                  <Projectdescription dangerouslySetInnerHTML={{
                        __html: serviceItem.seo_description,
                    }} />
                    {/* <Learnlink to={`/services/${serviceItem.slug}`}>
                      <StyledButton>Learn More</StyledButton>
                    </Learnlink> */}
              </Itemcontainer>
            </Col>
          </>
          )
        } else {
          return (
            <>
            <Col xs={12} md={6} className="d-none d-md-block">
              <Itemcontainer>
                <Learnlink to={`/services/${serviceItem.slug}`}><Projecttitle>{serviceItem.title}</Projecttitle></Learnlink>
                  <Projectdescription dangerouslySetInnerHTML={{
                        __html: serviceItem.seo_description,
                    }} />
                    {/* <Learnlink to={`/services/${serviceItem.slug}`}>
                      <StyledButton>Learn More</StyledButton>
                    </Learnlink> */}
              </Itemcontainer>
            </Col>
            <Col xs={12} md={6} className="d-none d-md-block">
            <Learnlink to={`/services/${serviceItem.slug}`}>
              <Projectimg image={serviceItem.service_image.imageFile.childImageSharp.gatsbyImageData} alt={serviceItem.title} objectPosition="center center" />
            </Learnlink>
            </Col>

            <Col xs={12} md={6} className="d-md-none d-xs">
            <Learnlink to={`/services/${serviceItem.slug}`}>
              <Projectimg image={serviceItem.service_image.imageFile.childImageSharp.gatsbyImageData} alt={serviceItem.title} objectPosition="center center" />
            </Learnlink>
            </Col>
            <Col xs={12} md={6} className="d-md-none d-xs">
            <Itemcontainer>
                <Learnlink to={`/services/${serviceItem.slug}`}><Projecttitle>{serviceItem.title}</Projecttitle></Learnlink>
                  <Projectdescription dangerouslySetInnerHTML={{
                        __html: serviceItem.seo_description,
                    }} />
                    {/* <Learnlink to={`/services/${serviceItem.slug}`}>
                      <StyledButton>Learn More</StyledButton>
                    </Learnlink> */}
              </Itemcontainer>
            </Col>

            </>
          )
        }
      })()}
            </Projectrow>
             ))}        
    
  </>
  );
}

export default Homeserviceslist;