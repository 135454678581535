import React from "react";
import { Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles


const Clientimg = styled(GatsbyImage)`
  margin: 0 auto;
`
const Clientrow = styled(Row)`
  background-color: ${({ theme }) => theme.colours.common.white};
  margin-bottom: 50px;
`


function Clientlist({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      clients(limit: 6) {
        id
        client_name
        client_logo {
          id
          title
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
  
  `)

  const clientNode = data.directus.clients;
 
  return (
    
    <>
    <Clientrow>
        {clientNode.map((clientItem, i) => 
        (   
        <Col xs={4} md={2} key={i}>
          <Clientimg image={clientItem.client_logo.imageFile.childImageSharp.gatsbyImageData} alt={clientItem.client_name} objectPosition="center center" />
        </Col>
             ))}
    </Clientrow>
  </>
  );
}

export default Clientlist;