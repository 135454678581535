import React from "react";
import { Container, Col, Row } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles
const Accredtitle = styled.span`
  color: ${({ theme }) => theme.colours.primary.main};
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: 1em 0;
  font-size: 18px;
  display: block;

`

const Accreddescription = styled.p`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 0.8em;

  @media (min-width: 576px) { 
    font-size: 16px;
  }
`

const Accredimg = styled(GatsbyImage)`
  margin: 20px auto;
  width: 100% !important;

  @media (min-width: 576px) { 
    margin: 0 auto;
  }

`

const Accredrow = styled(Row)`
  margin-bottom: 50px;
  background-color: ${({ theme }) => theme.colours.common.white};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  padding: 50px 25px;
`


function Accreditations({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      accreditations {
        title
        description
        accreditation_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED, formats: [AUTO, WEBP], height: 100)
            }
          }
        }
      }
    }
  }
  `)

  const accredNode = data.directus.accreditations;
 
  return (
    
    <>
  <Container className="p-0">
    <Accredrow>
        {accredNode.map((accredItem, i) => 
        (
            <Col xs={12} md={6} lg={4} key={i}>
              <Accredimg image={accredItem.accreditation_image.imageFile.childImageSharp.gatsbyImageData} alt={accredItem.title} objectPosition="center center" imgStyle={{ objectFit: 'contain' }} />
              <Accredtitle>{accredItem.title}</Accredtitle>
              <Accreddescription>{accredItem.description}</Accreddescription>
            </Col> ))}        
    </Accredrow>
  </Container>

  </>
  );
}

export default Accreditations;