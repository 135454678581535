import React from "react";
import { Col, Row, Accordion } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles

const Accorddescription = styled.p`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 1.4em;

  @media (min-width: 576px) { 
    font-size: 16px;
  }
`

const Accordtitle = styled(Accordion.Header)`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  display: block;

  & .accordion-button:not(.collapsed) {
    color: ${({ theme }) => theme.colours.primary.main};
    background-color: inherit;
    border-left: solid 5px ${({ theme }) => theme.colours.primary.main};
    font-size: 1.5rem;
    text-transform: uppercase;
  }

  & .accordion-button {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  
  & .accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23c63127'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
  }
    
  }

`

function Homeaccordion({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      home {
        professionalism
        training
        accreditation
      }
    }
  }
  
  `)
 
  return (
    
    <>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
          <Accordtitle>Professionalism</Accordtitle>
          <Accordion.Body dangerouslySetInnerHTML={{
                    __html: data.directus.home.professionalism,
                }} />
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordtitle>Accreditation</Accordtitle>
          <Accordion.Body dangerouslySetInnerHTML={{
                    __html: data.directus.home.accreditation,
                }} />
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordtitle>Training</Accordtitle>
          <Accordion.Body dangerouslySetInnerHTML={{
                    __html: data.directus.home.training,
                }} />
        </Accordion.Item>
      </Accordion>
  </>
  );
}

export default Homeaccordion;